/* Base responsive settings */
html {
  font-size: 16px;
}

/* Media query mixins for easier implementation */
@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 481px) and (max-width: 768px) {
    @content;
  }
}

@mixin small-screen {
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}

@mixin large-screen {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

/* Container adjustments */
.container {
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  
  @include mobile {
    padding: 0 1rem;
  }
}

/* Layout adjustments */
.home-page, .about-page, .contact-page, .projects-page {
  .text-zone {
    @include mobile {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
      width: 100%;
      padding-top: 5rem;
      padding-bottom: 2rem;
    }

    @include tablet {
      left: 5%;
      width: 80%;
    }

    @include small-screen {
      left: 7%;
      width: 60%;
    }

    h1 {
      @include mobile {
        font-size: 2rem;
        margin-top: 2rem;
      }

      @include tablet {
        font-size: 2.5rem;
      }
    }

    p {
      @include mobile {
        font-size: 1rem;
      }
    }
  }
}

/* Nav bar responsive adjustments */
.nav-bar {
  @include mobile {
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(25, 29, 31, 0.95);
    
    .logo {
      display: none;
    }
    
    nav {
      display: flex;
      position: static;
      height: auto;
      width: 100%;
      margin: 0;
      justify-content: space-around;
      
      a {
        margin: 0;
        font-size: 1.2rem;
        
        &:after {
          bottom: -0.5rem;
          font-size: 0.6rem;
        }
      }
    }
    
    ul {
      display: none;
    }
  }
  
  @include tablet {
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(25, 29, 31, 0.95);
    
    .logo {
      display: none;
    }
    
    nav {
      display: flex;
      position: static;
      height: auto;
      width: 100%;
      margin: 0;
      justify-content: space-around;
      
      a {
        margin: 0;
        font-size: 1.2rem;
        
        &:after {
          bottom: -0.5rem;
          font-size: 0.6rem;
        }
      }
    }
    
    ul {
      display: none;
    }
  }
}

/* About page cube spinner */
.stage-cube-cont {
  @include mobile {
    position: relative;
    width: 100%;
    height: 200px;
    padding: 0;
    margin: 2rem 0;
    right: auto;
  }
  
  @include tablet {
    width: 100%;
    right: 0;
    height: 300px;
    padding-top: 0;
  }
  
  @include small-screen {
    width: 40%;
    padding-top: 10%;
  }
}

/* Projects layout */
.projects-box {
  @include mobile {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 1rem;
    margin-top: 4rem;
  }
  
  @include tablet {
    width: 90%;
    padding: 1rem;
  }
  
  .nba-data, .project4 {
    @include mobile {
      margin-bottom: 3rem;
      
      .flat-button {
        width: 80%;
        margin: 1rem auto;
      }
      
      .project-descs {
        margin-bottom: 1rem;
      }
    }
    
    @include tablet {
      margin-bottom: 3rem;
      
      .flat-button {
        width: 80%;
        margin: 1rem auto;
      }
      
      .project-descs {
        margin-bottom: 1rem;
      }
    }
  }
}

/* Contact form */
.contact-form {
  @include mobile {
    ul {
      li.half {
        width: 100%;
        margin-left: 0;
        
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

/* Home page feature image */
.home-feature {
  @include mobile {
    display: none;
  }
  
  @include tablet {
    width: 90%;
    height: auto;
    margin-left: 5%;
    margin-top: 70%;
  }
  
  @include small-screen {
    width: 50%;
    margin-left: 45%;
  }
  
  img {
    @include tablet {
      width: 100%;
      height: auto;
    }
    
    @include small-screen {
      width: 100%;
      height: auto;
    }
  }
}

/* Add this to ensure the page content doesn't get hidden under the mobile nav bar */
@include mobile {
  body {
    padding-bottom: 70px;
  }
  
  .loader {
    bottom: 80px;
  }
}

@include tablet {
  body {
    padding-bottom: 70px;
  }
  
  .loader {
    bottom: 80px;
  }
}