.projects-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .text-zone {
    left: 45%;
    top: 1%;
  }
  h2 {
    color: #6096ba;
    font-size: 24px;
    font-family: 'Coolvetica';
  }

  
}

.projects-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);  
  height: 80%;
  width: 50%;
  padding: 2%;
  margin-bottom: 5%;
  gap: 20px;
}



.nba-data,
.focus-timer,
.globe-simulation,
.project4 {
  border: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align:center;
  height: auto; 
  border-radius: 8px;


  .flat-button{
    display: flexbox;
    grid-template-columns: repeat(4,1fr);
    text-align: center;
    width: 55%;
    margin-left: 20%;
    white-space: normal;
    
  } 
}

.nba-logo,
.focus-logo,
.globe-logo,
.tbd-logo {
  max-width: 100%;
  height: auto; 
  border-radius: 8px;
  margin-bottom: -10px; 
  display: block;
  padding: 0%;
}

.project-descs {
  display: inline-block;
  font-size: 18px;
  color: #8d8d8d;
  font-weight: 500;
  min-width: fit-content;
  opacity: 100%;
  margin-bottom: 40%;

}
