.nav-bar {
  background: #191D1F;
  width: 75px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
}

.nav-bar .logo {
  display: block;
  padding: 8px 0;
}

.nav-bar .logo img {
  display: block;
  margin: 8px auto;
  width: 69px;
  height: auto;
}

.nav-bar nav {
  display: block;
  text-align: center;
  position: absolute;
  height: 210px;
  top: 50%;
  margin-top: -120px;
  width: 100%;
}

.nav-bar nav a {
  font-size: 22px;
  color: #4d4d4e;
  display: block;
  line-height: 51px;
  height: 51px;
  position: relative;
  text-decoration: none;
}

.nav-bar nav a i {
  transition: all 0.3s ease-out;
}

.nav-bar nav a:hover {
  color: #ffd700;
}

.nav-bar nav a:hover svg {
  opacity: 0;
}

.nav-bar nav a:hover:after {
  opacity: 1;
}

.nav-bar nav a:after {
  content: '';
  font-size: 9px;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.nav-bar nav a:first-child:after {
  content: 'HOME';
}

.nav-bar nav a.about-link:after {
  content: 'ABOUT';
}

.nav-bar nav a.projects-link:after {
  content: 'PROJECTS';
}

.nav-bar nav a.contact-link:after {
  content: 'CONTACT';
}

.nav-bar nav a.active svg {
  color: #4d4d4e;
  
}

.nav-bar ul {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: block;
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0;
}

.nav-bar ul li a {
  padding: 7px 0;
  display: block;
  font-size: 40px;
  line-height: 16px;
}

.nav-bar ul li a:hover svg {
  color: #ffd700;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .nav-bar {
    width: 100%;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }

  .nav-bar .logo {
    padding: 0;
    margin-left: 10px;
  }

  .nav-bar .logo img {
    width: 50px;
    margin: 0;
  }

  .nav-bar nav {
    position: static;
    height: auto;
    top: 0;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .nav-bar nav a {
    font-size: 18px;
    line-height: normal;
    height: auto;
    display: inline-block;
    padding: 0 10px;
  }

  .nav-bar ul {
    position: static;
    bottom: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .nav-bar ul li {
    margin: 0 50px;
  }

  .nav-bar ul li a {
    font-size: 24px;
    line-height: normal;
  }
}
